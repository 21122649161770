/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react'
import NextRouter, { useRouter } from 'next/router'
import { handleClientScriptLoad } from 'next/script'
import { Box, Container, Flex, Heading, Spacer, Text, useBreakpointValue } from '@chakra-ui/react'
import algoliasearch from 'algoliasearch/lite'
import { useFormat } from 'helpers/hooks/useFormat'
import { addCAPIFbEvent, callCAPIEventForPageView, getCAPIrootEventsProps } from 'helpers/metaCapiHelper'
import { constructUtagData, utagView } from 'helpers/tealiumHelper'
import { appendEpsilonScript } from 'helpers/utils/epsilonScript'
import { createInstantSearchRouterNext } from 'react-instantsearch-hooks-router-nextjs'
import { InstantSearch } from 'react-instantsearch-hooks-web'
import { useAtgLegacyCart, useAtgUser } from 'frontastic/contexts'
import { updateCurrencyToUSD } from 'frontastic/contexts/atgCartContext/helpers/httpRequests'
import { NewCertonaSlider } from 'frontastic/tastics/composable/new-certona-slider'
import { getGoogleAnalyticsUserIdFromBrowserCookie } from 'frontastic/tastics/composable/product-detail-page/helpers'
import { monetateView } from 'frontastic/tastics/monetate'
import { AlgoliaConfiguration } from './components/filtering/algolia-configuration'
import { CurrentRefinements } from './components/filtering/current-refinements'
import { InfiniteHits } from './components/filtering/infinite-hits'
import { SortBy } from './components/filtering/sort-by'
import { Breadcrumb } from './components/parts/breadcrumb'
import { Filters } from './components/parts/filters'
import { FiltersModal } from './components/parts/filters-modal'
import { GridLayoutControls } from './components/parts/grid-layout-controls'
import { ItemCount } from './components/parts/item-count'
import { NoResultsBoundary } from './components/parts/no-results-boundary'
import { ALGOLIA_INDEX_LIST, NOT_ACCEPTED_PARAMS } from './constants'
import { GridLayoutProvider } from './hooks/use-grid-layout'
import { createInfiniteHitsSessionStorageCustomCache } from './infinite-hits-cache'
import { NavigationBar } from './navigation-bar'
import { PageHeader } from './page-header'
import { getStateMapping } from './router'
import { Title } from './title'
import { algoliaIndexNameResolver, queryParamsAccepted } from './utils'
import { ContentstackRichText as ContentstackRichTextProps } from '../../../../../_shared/types/contentstack'
import { getScriptType } from '../../../helpers/utils/appendScript'
import { ContentstackRichText } from '../contentstack/rich-text'
import {
  CERTONA_CATEGORY_RV_UID,
  CERTONA_SEARCHPAGE_RV_UID,
  CERTONA_SEARCHPAGE_YMAL_UID,
  CERTONA_SUBCATEGORY_YMAL_UID,
  CERTONA_SUBSUBCATEGORY_YMAL_UID,
  FUNCTIONAL_COOKIE_CATEGORY,
} from '../general/constants'
import useContentstack from '../hooks/useContentstack'
import { getCookieByName } from '../mystore/utils'

declare const window: any

const algoliaConfig = {
  appId: process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID || '',
  searchApiKey: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY || '',
}

const basePathnamesWithCacheEnabled = ['search', 'c']
let sessionStorageCache = createInfiniteHitsSessionStorageCustomCache(basePathnamesWithCacheEnabled)
const searchClient = algoliasearch(algoliaConfig.appId, algoliaConfig.searchApiKey)
const categoryIndex = searchClient.initIndex(process.env.NEXT_PUBLIC_ALGOLIA_CATEGORY_INDEX)
const RICH_TEXT = 'rich_text'

export interface ProductListingPageProps {
  query: string
  isSearchPage?: boolean
}

export const ProductListingPage = ({ isSearchPage, query }: ProductListingPageProps) => {
  const [isLoadingCategory, setIsLoadingCategory] = useState(true)
  const [category, setCategory] = useState<any>()
  const [queryId, setQueryId] = useState<string | null>(null)
  const [endPageRichText, setEndPageRichText] = useState<ContentstackRichTextProps>()
  const router = useRouter()
  const path = router.asPath
  const { getEntryBySlug, isLoading } = useContentstack()
  const { userSessionData, userDetails } = useAtgUser()
  const [products, setProducts] = useState('')
  const CONTENT_TYPE_ID = 'component_certona_slider'
  const [youMayAlsoLike, setYouMayAlsoLike] = useState(null)
  const [recentlyViewedItems, setRecentlyViewedItems] = useState(null)
  const { getEntryByUid } = useContentstack()
  const [certonaRecommendationsData, setCertonaRecommendationsData] = useState(null)
  const { fetchUserSessionData } = useAtgUser()
  const [isSearchResultNotFound, setIsSearchResultNotFound] = useState<boolean>(false)
  const [isMonetateViewCalled, setIsMonetateViewCalled] = useState<boolean>(false)
  const handleProductsData = (dataFromChild) => {
    setProducts(dataFromChild)
    setIsMonetateViewCalled(false)
  }

  const handleIsSearchResultNotFound = (isSearchResultNotAvailable: boolean) => {
    setIsSearchResultNotFound(isSearchResultNotAvailable)
  }
  const gaUserToken = useMemo(() => {
    return getGoogleAnalyticsUserIdFromBrowserCookie('_ga')
  }, [])

  const categoryHeading = category?.h1 ? category?.h1 : ''

  useEffect(() => {
    const { e4x_auto } = router.query
    const currency = getCookieByName('dxlcurrency')
    if (e4x_auto && e4x_auto == 'false' && currency !== 'USD') {
      const res = updateCurrencyToUSD()
    }
  }, [])

  useEffect(() => {
    try {
      if (typeof (window as any)?.POWERREVIEWS == 'undefined') {
        handleClientScriptLoad({
          src: '//ui.powerreviews.com/stable/4.1/ui.js',
          id: 'powerReviewScript',
          className: getScriptType(FUNCTIONAL_COOKIE_CATEGORY),
        })
      }
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    if (query) {
      findEndPageData()
    }
  }, [query])

  useEffect(() => {
    if (isSearchPage) {
      const epsilonConfig = {
        dtm_fid: 5419,
        dtm_promo_id: 7,
        dtmc_department: 'search',
        dtm_user_id: userDetails?.profile?.['crmId'] ? userDetails?.profile?.['crmId'] : null,
        dtm_email_hash: userDetails?.profile?.['email'] ? userDetails?.profile?.['email'] : null,
      }
      appendEpsilonScript(epsilonConfig, 'epsilonplp')
    } else {
      if (category) {
        const categoryName = getCateogry(1)
        const subcategoryName = getCateogry(2)
        const subsubcategoryName = getCateogry(3)
        if (categoryName) {
          const epsilonConfig = {
            dtm_fid: 5419,
            dtm_promo_id: subsubcategoryName ? 4 : subcategoryName ? 3 : 2,
            dtmc_department: categoryName,
            dtmc_category: subcategoryName,
            dtmc_sub_category: subsubcategoryName,
            dtm_user_id: userDetails?.profile?.['crmId'] ? userDetails?.profile?.['crmId'] : null,
            dtm_email_hash: userDetails?.profile?.['email'] ? userDetails?.profile?.['email'] : null,
          }
          appendEpsilonScript(epsilonConfig, 'epsilonplp')
        }
      }
    }
  }, [category])

  const getCateogry = (level) => {
    return category?.breadcrumbs?.length && category?.breadcrumbs[level] ? category?.breadcrumbs[level].name : null
  }

  const findEndPageData = async () => {
    const response = await getEntryBySlug(RICH_TEXT, query)
    setEndPageRichText(response)
  }

  useEffect(() => {
    findCategory({ categoryId: query })
  }, [query])

  useEffect(() => {
    const interval = setInterval(() => {
      if (window.utag && category && products?.length) {
        utagViewSearch(category, products, queryId)
        clearInterval(interval)
      }
    }, 100)

    return () => clearInterval(interval)
  }, [category, products, queryId])

  const findCategory = async ({ categoryId }) => {
    try {
      const results = await categoryIndex.search('', {
        filters: `objectID:${categoryId}`,
        attributesToRetrieve: ['name', 'breadcrumbs', 'h1'],
        userToken: userDetails?.profile?.crmId || gaUserToken,
        clickAnalytics: true,
      })
      if (results?.hits?.length > 0) {
        setCategory(results.hits[0])
        setIsMonetateViewCalled(false)
      }
      setQueryId(results?.queryID || '')
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoadingCategory(false)
    }
  }

  const { formatMessage } = useFormat({ name: 'common' })
  const algoliaIndex = algoliaIndexNameResolver({ locale: router.locale ?? router.defaultLocale })

  const utagCmPageId = (category) => {
    const bCrumb = category?.breadcrumbs
    const pageType =
      category?.breadcrumbs?.length == 2
        ? 'category'
        : category?.breadcrumbs?.length == 3
          ? 'subcategory'
          : 'subsubcategory'
    let label

    if (typeof pageType !== 'undefined') {
      if (isSearchPage) {
        label = 'search'
      } else {
        let i: number
        const bcLength: number = bCrumb?.length - 1
        for (i = 0; i <= bcLength; i++) {
          if (i === bcLength) {
            label = bCrumb[i].name
          }
        }
      }
    }

    return label
  }

  const getCategoryLevel = (breadcrumb, level) => {
    return breadcrumb && breadcrumb.length ? breadcrumb[level]?.name : null
  }

  const getPagetype = (category) => {
    if (isSearchPage) {
      return 'search'
    }

    const breadcrumbsLength = category?.breadcrumbs?.length

    switch (breadcrumbsLength) {
      case 2:
        return 'category'
      case 3:
        return 'subcategory'
      case 4:
        return 'subsubcategory'
      default:
        return null
    }
  }

  const formatBreadcrumb = (breadcrumb) => {
    let bc = ''
    if (breadcrumb) {
      breadcrumb.forEach((item, index) => {
        if (index < breadcrumb.length - 1) {
          bc += item.name + ' > '
        } else {
          bc += item.name
        }
      })
    }
    return bc
  }

  const getItemCategories = (records) => {
    const categories = []
    records.forEach((record) => {
      categories.push(
        category.breadcrumbs && category.breadcrumbs[1] ? category.breadcrumbs[1].name.toLocaleLowerCase() : '',
      )
    })
    return categories
  }

  const getItemNames = (records) => {
    const names = []
    records.forEach((record) => {
      if (record.name) {
        names.push(record.name)
      }
    })
    return names
  }

  const getProductIds = (records) => {
    const pIds = []

    records.forEach((record) => {
      if (record?.key) {
        pIds.push(record.key)
      }
    })

    return pIds
  }

  const getProductLists = (records) => {
    const valuesList = []
    records.forEach((record) => {
      isSearchPage ? valuesList.push('search - search') : valuesList.push('browse - plp')
    })
    return valuesList
  }

  const getItemPositions = (records) => {
    return Array.from({ length: records.length }, (_, index) => (index + 1).toString())
  }

  const getItemPrices = (records) => {
    const prices = []
    records.forEach((record) => {
      prices.push(('' + record.basePrice?.centAmount)?.replace(',', ''))
    })
    return prices
  }

  const getItemSkus = (records) => {
    const skus = []
    records.forEach((record) => {
      if (record.objectID) {
        skus.push(record.objectID)
      }
    })
    return skus
  }

  const utagViewSearch = (category, hits, queryId: string) => {
    const utag_data = constructUtagData(path, userDetails, userSessionData, {
      page_type: getPagetype(category),
      cm_category_id: utagCmPageId(category),
      product_category: isSearchPage ? ['search'] : getCategoryLevel(category.breadcrumbs, 1),
      product_category2: isSearchPage ? null : getCategoryLevel(category.breadcrumbs, 2),
      product_category3: isSearchPage ? null : getCategoryLevel(category.breadcrumbs, 3),
      site_section: isSearchPage ? ['search'] : getCategoryLevel(category.breadcrumbs, 1),
      site_sub_section: isSearchPage ? null : getCategoryLevel(category.breadcrumbs, 2),
      site_sub_section_level3: isSearchPage ? null : getCategoryLevel(category.breadcrumbs, 3),
      site_sub_section_level4: isSearchPage ? null : getCategoryLevel(category.breadcrumbs, 4),
      site_breadcrumb: formatBreadcrumb(category.breadcrumbs),
      page_name: formatBreadcrumb(category.breadcrumbs),
      product_impression_category: getItemCategories(hits),
      product_impression_name: getItemNames(hits),
      product_impression_id: getProductIds(hits),
      product_impression_list: getProductLists(hits),
      product_impression_position: getItemPositions(hits),
      product_impression_prices: getItemPrices(hits),
      product_algolia_query_id: [queryId ?? ''],
      //'product_impression_variant': this.getImpressionColors(data),
      product_impression_sku: getItemSkus(hits),
    })
    utagView(utag_data, userSessionData?.dxlCountry, userSessionData?.dxlCurrency)
    callCAPIEventForPageView(userSessionData?.dxlCountry)
    if (isSearchPage) {
      callFBMetaSearchEvent()
    }
  }

  const certonaRecommendations = (data) => {
    setCertonaRecommendationsData(data)
  }

  useEffect(() => {
    window.certonaRecommendations = certonaRecommendations
    setYouMayAlsoLike(null)
    setRecentlyViewedItems(null)
    if (isSearchPage) {
      fetchCSCertonaData(CERTONA_SEARCHPAGE_YMAL_UID, setYouMayAlsoLike) //search
      fetchCSCertonaData(CERTONA_SEARCHPAGE_RV_UID, setRecentlyViewedItems) //search recently viewed
    } else {
      const pageType = getPagetype(category)
      if (pageType === 'subcategory') {
        fetchCSCertonaData(CERTONA_SUBCATEGORY_YMAL_UID, setYouMayAlsoLike) // subcategory
      } else if (pageType === 'subsubcategory') {
        fetchCSCertonaData(CERTONA_SUBSUBCATEGORY_YMAL_UID, setYouMayAlsoLike) // subsubcategory
      } //TODO: verify first when brand category is available
      //else if (pageType === 'brand') {
      //   fetchCSCertonaData(CERTONA_BRANDS_YMAL_UID, setYouMayAlsoLike); // brands
      // }
      else {
        console.warn('Unknown page type:', pageType)
      }
      fetchCSCertonaData(CERTONA_CATEGORY_RV_UID, setRecentlyViewedItems) //category recently viewed
    }
    return () => {
      window.certonaRecommendations = null
    }
  }, [query, category])

  const fetchCSCertonaData = async (uid, setter) => {
    try {
      const results = await getEntryByUid(CONTENT_TYPE_ID, uid)
      setter(results)
    } catch (err) {
      console.log('Error processing contentStack data', err)
    }
  }

  const callFBMetaSearchEvent = () => {
    const eventData = {
      events: [
        {
          ...getCAPIrootEventsProps('Search'),
          custom_data: [
            {
              key: 'search_string',
              value: query,
            },
          ],
          customer_information: [
            {
              key: 'country',
              value: userSessionData?.dxlCountry,
            },
          ],
        },
      ],
      partner_agent: 'liveramp',
    }

    addCAPIFbEvent(eventData)
  }

  const queryParamsToPreserve = useMemo(() => {
    const { ...queryParams } = router.query

    return queryParamsAccepted(queryParams, NOT_ACCEPTED_PARAMS)
  }, [router.query])

  const algoliaStateMapping = useMemo(() => {
    return getStateMapping({
      indexName: algoliaIndex,
      locale: router.locale ?? router.defaultLocale,
      indexNameResolver: algoliaIndexNameResolver,
      extraParams: queryParamsToPreserve,
    })
  }, [router.locale, algoliaIndex, isSearchPage, getStateMapping])

  const { cartData } = useAtgLegacyCart()

  useEffect(() => {
    const breadCrumbs = isSearchPage ? ['Search Results'] : category?.breadcrumbs?.map((bc) => bc.name)
    const productIdArray = !!products ? getProductIds(products) : []
    const searchKeyword = isSearchPage ? query : ''
    const plpData = {
      breadCrumbs: breadCrumbs,
      productIdArray: productIdArray,
      isPlpSearchPage: isSearchPage,
      searchKeyword: searchKeyword,
    }

    if (!isMonetateViewCalled && !!breadCrumbs && productIdArray.length > 0) {
      if (!isSearchPage) {
        monetateView(cartData, path, plpData)
        setIsMonetateViewCalled(true)
      } else if (isSearchPage && !isSearchResultNotFound) {
        monetateView(cartData, path, plpData)
        setIsMonetateViewCalled(true)
      }
    }
  }, [cartData, isSearchResultNotFound, category?.breadcrumbs, products])

  const isMobile = useBreakpointValue({ base: true, lg: false }, { fallback: 'lg' })

  const infiniteHitsProps = useMemo(() => ({ cache: sessionStorageCache }), [sessionStorageCache])

  return (
    <Container
      aria-live="polite"
      aria-busy={true}
      maxW="100rem"
      minH={['auto', 'auto', 'container.sm']}
      px={{ base: 4, lg: 8, xl: 12, '2xl': 24, '3xl': 32 }}
      my={[4, 6, 16]}
    >
      <Box id="mt_container_top_header"></Box>
      <InstantSearch
        searchClient={searchClient}
        indexName={algoliaIndex}
        routing={{
          router: createInstantSearchRouterNext({ singletonRouter: NextRouter }),
          stateMapping: algoliaStateMapping,
        }}
      >
        <GridLayoutProvider>
          <AlgoliaConfiguration query={query} isSearchPage={isSearchPage} />
          <NoResultsBoundary
            catchOn="unfiltered-search-only"
            isSearchPage={isSearchPage}
            handleIsSearchResultNotFound={handleIsSearchResultNotFound}
          >
            <Box mt={{ base: 6, lg: 16 }} mb={{ base: 6, lg: 12 }}>
              <Box mb={{ base: 2, lg: 3 }}>
                <Breadcrumb
                  isLoading={isLoadingCategory}
                  query={query}
                  isSearchPage={isSearchPage}
                  items={category?.breadcrumbs}
                />
              </Box>
              <Title {...{ categoryH1: category?.h1, isLoading: isLoadingCategory, isSearchPage, query }} />
            </Box>

            <PageHeader query={query} />
            <NavigationBar query={query} displayImages />

            <Flex direction={{ base: 'column', lg: 'row' }} alignItems="stretch">
              <Box flexBasis={72} flexShrink={0} display={{ base: 'none', lg: 'initial' }}>
                <Text tabIndex={0} as="h2" textStyle={'heading-desktop-300'} mb={3}>
                  {formatMessage({ id: 'category.filters.refineBy' })}
                </Text>
                <Filters infiniteHitsProps={infiniteHitsProps} />
              </Box>
              <Flex direction="column" w="full" ml={{ base: 0, lg: 6 }} mt={{ base: 4, lg: 0 }}>
                <Flex align="center" alignItems="end">
                  <ItemCount infiniteHitsProps={infiniteHitsProps} />
                  <Spacer />
                  <GridLayoutControls />
                  <Box ml={8} display={{ base: 'none', lg: 'initial' }}>
                    <SortBy items={ALGOLIA_INDEX_LIST} indexNameResolver={algoliaIndexNameResolver} />
                  </Box>
                </Flex>

                {isMobile && (
                  <Flex id="mobileView" mt={5}>
                    <Flex w="50%" direction="column" px={2} pl={0}>
                      <FiltersModal infiniteHitsProps={infiniteHitsProps} />
                    </Flex>
                    <Box w="50%" px={2}>
                      <SortBy items={ALGOLIA_INDEX_LIST} indexNameResolver={algoliaIndexNameResolver} />
                    </Box>
                  </Flex>
                )}

                <CurrentRefinements />

                <Box mt={{ base: 8, lg: 10 }} mb={6}>
                  <NoResultsBoundary
                    catchOn="filtered-search-only"
                    isSearchPage={isSearchPage}
                    handleIsSearchResultNotFound={handleIsSearchResultNotFound}
                  >
                    <InfiniteHits
                      productListType={isSearchPage ? 'search - search' : 'browse - plp'}
                      onHitData={handleProductsData}
                      cache={sessionStorageCache}
                      algoliaQueryId={queryId}
                    />
                  </NoResultsBoundary>
                </Box>
              </Flex>
            </Flex>

            <Box id="mt_container_showmore1"></Box>
            <Box id="mt_container_showmore2"></Box>
            {youMayAlsoLike?.uid && !!certonaRecommendationsData !== null && (
              <NewCertonaSlider certonaRes={certonaRecommendationsData} {...youMayAlsoLike} />
            )}
            {!!endPageRichText && <ContentstackRichText {...endPageRichText} desktop_container_width="900px" />}
            {recentlyViewedItems?.uid && !!certonaRecommendationsData && (
              <NewCertonaSlider certonaRes={certonaRecommendationsData} {...recentlyViewedItems} />
            )}
            <Box id="mt_container_beofer_footer1"></Box>
            <Box id="mt_container_beofer_footer2"></Box>
          </NoResultsBoundary>
        </GridLayoutProvider>
      </InstantSearch>
    </Container>
  )
}
