import { FunctionComponent, useState } from 'react'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { Box, Text, VStack, useBreakpointValue, Flex, useToken, Button, useToast } from '@chakra-ui/react'
import { sizes } from 'composable/chakra/figma-tokens'
import { Badge } from 'composable/components/badge'
import ProductBadges from 'composable/components/general/components/productbadges'
import { useCheckIfProductHasBadge } from 'composable/components/hooks/useCheckIfProductHasBadge/utils'
import PDPQuickViewModal from 'composable/components/pdp/pdp-quick-view-modal'
import { PrRatings } from 'composable/components/powerreview/pr-ratings'
import { CurrencyHelpers } from 'helpers/currencyHelpers'
import { useFormat } from 'helpers/hooks/useFormat'
import { QuickViewData, useQuickViewProduct } from 'helpers/hooks/useQuickViewProduct'
import { utagProductClick } from 'helpers/tealiumHelper'
import { IoImagesOutline } from 'react-icons/io5'
import { useAtgUser, useStore } from 'frontastic/contexts'
import { ProductSwatchSelectorSize, SwatchSelector } from './swatch-selector'
import { Thumbnails } from '../../../types'
import { PLPPriceLayouts, StarterKitAlgoliaProduct } from '../../types'
import { defineLayout, isGiftOrDonation } from '../../utils'
import {
  calculateFinalDiscountPercentage,
  deduplicateAndSort,
  limitToMinMax,
} from 'composable/helpers/utils/product-card-utils'

interface CategoryProductCardProps {
  product: Partial<StarterKitAlgoliaProduct>
  priority?: boolean
  idx: number
  productListType?: string
  algoliaQueryId?: string
}

interface ImageWithButtonProps {
  imageSrc: string
  imageAlt: string
  onButtonClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  intl: any
}

const StandardPrice = ({ prices }) => {
  if (prices.centAmount.length === 1) {
    return (
      <Box display={'flex'} flexDirection={'row'} gap={1}>
        <Text tabIndex={0} textStyle={'body-75'}>
          {CurrencyHelpers.formatForCurrency(prices.centAmount[0])}
        </Text>
      </Box>
    )
  } else if (prices.centAmount.length === 2) {
    return (
      <Box display={'flex'} flexDirection={'row'} gap={1}>
        <Text tabIndex={0} textStyle={'body-75'}>
          {CurrencyHelpers.formatForCurrency(prices.centAmount[0])}
        </Text>
        <Text textStyle={'body-75'}>{` - `}</Text>
        <Text tabIndex={0} textStyle={'body-75'}>
          {CurrencyHelpers.formatForCurrency(prices.centAmount[1])}
        </Text>
      </Box>
    )
  } else {
    return null
  }
}

const DiscountPrice = ({ prices, basePrice, discountAmount, discountedPrice, isClearance, productKey }) => {
  const intl = useFormat({ name: 'common' })

  let finalSellingPrice = discountedPrice ? [discountedPrice.centAmount, ...prices.centAmount] : [...prices.centAmount]
  let finalOriginalPrice = discountedPrice ? [basePrice.centAmount, ...prices.centAmount] : [basePrice.centAmount]

  finalSellingPrice = limitToMinMax(deduplicateAndSort(finalSellingPrice))
  finalOriginalPrice = limitToMinMax(deduplicateAndSort(finalOriginalPrice))

  const finalDiscountPercentage = calculateFinalDiscountPercentage(
    finalOriginalPrice,
    finalSellingPrice,
    discountAmount,
  )

  return (
    <Box display={'flex'} flexDirection={'column'} gap={1}>
      <Box display={'flex'} flexDirection={'row'} gap={1} flexWrap={'wrap'}>
        {finalSellingPrice[0] !== finalOriginalPrice[finalOriginalPrice.length - 1] &&
          finalSellingPrice.length !== 2 && (
            <Text tabIndex={0} textStyle={'figma-strikethrough-75'}>
              {CurrencyHelpers.formatForCurrency(finalOriginalPrice[finalOriginalPrice.length - 1])}
            </Text>
          )}

        {finalSellingPrice.length === 1 && (
          <Text tabIndex={0} textStyle={'body-75'}>
            {CurrencyHelpers.formatForCurrency(finalSellingPrice[0])}
          </Text>
        )}

        {finalSellingPrice.length === 2 && (
          <Box display={'flex'} flexDirection={'row'} gap={1}>
            <Text tabIndex={0} textStyle={'body-75'}>
              {CurrencyHelpers.formatForCurrency(finalSellingPrice[0])}
            </Text>
            <Text textStyle={'body-75'}>{` - `}</Text>
            <Text tabIndex={0} textStyle={'body-75'}>
              {CurrencyHelpers.formatForCurrency(finalSellingPrice[1])}
            </Text>
          </Box>
        )}

        {finalDiscountPercentage && (
          <Text tabIndex={0} textStyle={'body-75'} color={'danger.600'}>
            {finalSellingPrice.length === 1
              ? intl.formatMessage({
                  id: 'product.discount.label',
                  values: { discountPercent: Math.round(finalDiscountPercentage) },
                })
              : intl.formatMessage({
                  id: 'product.discountMax.label',
                  values: { discountPercent: Math.round(finalDiscountPercentage) },
                })}
          </Text>
        )}
      </Box>
    </Box>
  )
}

const EdsPromoPrice = ({ prices, edsPrice }) => {
  const intl = useFormat({ name: 'common' })

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Text tabIndex={0} textStyle={'body-75'} whiteSpace="normal">
        {prices.centAmount.length === 1
          ? `${CurrencyHelpers.formatForCurrency(prices.centAmount[0])}`
          : `${CurrencyHelpers.formatForCurrency(prices.centAmount[0])} - ${CurrencyHelpers.formatForCurrency(
              prices.centAmount[1],
            )}`}
        {` `}
        {intl.formatMessage({
          id: 'product.promotion.plp.label',
          values: { edsPrice: CurrencyHelpers.formatForCurrency(edsPrice.centAmount) },
        })}
      </Text>
    </Box>
  )
}

const PriceSection = ({ product }) => {
  if (!product?.price || isGiftOrDonation(product)) return null

  const basePrice = product.basePrice
  const priceOrPricesArray = product.price
  const edsPrice = product.edsPrice
  const discountAmount = product.discountAmount
  const discountedPrice = product.discounted
  const isClearance = product.isClearance

  const priceLayout = defineLayout({
    discountAmount,
    edsPrice,
    basePrice,
    priceOrPricesArray,
  })

  if (priceLayout === PLPPriceLayouts.STANDARD) {
    return <StandardPrice prices={priceOrPricesArray} />
  }

  if (priceLayout === PLPPriceLayouts.DISCOUNT_EVENT) {
    return (
      <DiscountPrice
        prices={priceOrPricesArray}
        basePrice={basePrice}
        discountAmount={discountAmount}
        discountedPrice={discountedPrice}
        isClearance={isClearance}
        productKey={product.key}
      />
    )
  }

  if (priceLayout === PLPPriceLayouts.EDS_PROMO) {
    return <EdsPromoPrice prices={priceOrPricesArray} edsPrice={edsPrice} />
  }
}

const ImageWithQuickViewButton: React.FC<ImageWithButtonProps> = ({ imageSrc, imageAlt, onButtonClick, intl }) => {
  const [isFocused, setIsFocused] = useState(false)

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    onButtonClick?.(event)
  }

  return (
    <Box
      position="relative"
      display="inline-block"
      width="100%"
      height="100%"
      overflow="hidden"
      _hover={{
        '& div': { opacity: 0.9 },
      }}
    >
      <ProductImage imageSrc={imageSrc} imageAlt={imageAlt} />
      <Box
        position="absolute"
        left="50%"
        bottom={0}
        transform="translateX(-50%)"
        opacity={isFocused ? 0.9 : 0}
        transition="opacity 0.3s ease"
        pointerEvents="auto"
        bg="white"
        height="61px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        w="full"
      >
        <Button
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          variant="outline"
          bg="transparent"
          color="black"
          _hover={{ bg: '#E1E5E8' }}
          _active={{ bg: '#F2F3F3' }}
          fontWeight="bold"
          my={sizes['2-5']}
          mx={sizes['4']}
          w="full"
          onClick={handleButtonClick}
        >
          {intl.formatMessage({ id: 'action.quickView' })}
        </Button>
      </Box>
    </Box>
  )
}

const ProductImage: React.FC<{ imageSrc: string; imageAlt: string; productName?: string }> = ({
  imageSrc,
  imageAlt,
  productName,
}) => {
  const [brandSecondary] = useToken('colors', ['brand.secondary'])

  if (imageSrc) {
    return <Image src={imageSrc} alt={imageAlt} layout="fill" objectFit="contain" />
  }

  return (
    <Flex
      role={'img'}
      aria-label={productName}
      justify={'center'}
      align={'center'}
      width={'full'}
      height={'full'}
      bgColor={'brand.muted'}
    >
      <IoImagesOutline size={'40px'} color={brandSecondary} />
    </Flex>
  )
}

export const CategoryProductCard: FunctionComponent<CategoryProductCardProps> = ({
  product,
  priority = false,
  idx,
  productListType,
  algoliaQueryId,
}) => {
  const router = useRouter()
  const { currentPDPVariant } = useStore()
  const toast = useToast()
  const intl = useFormat({ name: 'common' })
  const { onOpen, fetchProductData, isOpen, onClose } = useQuickViewProduct({ key: product.key })
  const isMobile = useBreakpointValue({ base: true, md: false })
  const isDesktop = useBreakpointValue({ base: false, md: false, lg: true })
  const [selectedImage, setSelectedImage] = useState<Thumbnails | undefined>(
    product?.thumbnails?.length ? product?.thumbnails[0] : undefined,
  )
  const [quickViewModalData, setQuickViewModalData] = useState<QuickViewData>()
  const { userSessionData } = useAtgUser()
  const showBadge = product.discountAmount || product.isClearance
  const watcherSize: ProductSwatchSelectorSize = useBreakpointValue({
    '2xs': 'small',
    xs: 'small',
    sm: 'small',
    md: 'small',
    lg: 'small',
    xl: 'large',
    '2xl': 'large',
    '3xl': 'large',
  })

  const imageSrc = selectedImage?.image_url
    ? selectedImage.image_url
    : product.image?.url !== ''
      ? product.image?.url
      : undefined
  const imageAlt = selectedImage?.alt ? selectedImage.alt : product.image?.label || product.name

  const handleCategoryProductCardClick = (e) => {
    e.preventDefault()
    e.stopPropagation()

    localStorage?.setItem(
      'lastProductClicked',
      JSON.stringify({
        product_list: [productListType],
        product_impression_position: [idx + 1],
      }),
    )
    localStorage?.setItem('lastProductSlugVisited', product.slug)
    localStorage?.setItem('algoliaQueryId', algoliaQueryId)
    localStorage?.setItem('swatch', selectedImage.color_name ?? '')

    utagProductClick(product, selectedImage?.color_name, selectedImage.id, userSessionData, algoliaQueryId)
    router.push(`/p/${product.slug}`)
  }
  const { hasBadge, badgeValues } = useCheckIfProductHasBadge(product, true, currentPDPVariant, router.asPath)

  const handleQuickViewClick = async (e: any) => {
    e.preventDefault()
    e.stopPropagation()

    const response = await Promise.resolve(fetchProductData(product.key ?? ''))
    const quickViewData = { ...response } as QuickViewData

    if (quickViewData?.product) {
      setQuickViewModalData(quickViewData)
      onOpen()
    } else {
      toast({
        description: 'Product out of stock. Please try again later.',
        status: 'error',
        duration: 2000,
        position: 'top',
      })
    }
  }

  return (
    <>
      {quickViewModalData && (
        <PDPQuickViewModal
          data={quickViewModalData}
          onClose={onClose}
          isOpen={isOpen}
          algoliaQueryId={algoliaQueryId}
        />
      )}

      <VStack as="article" w="100%" spacing={3} alignItems="initial">
        <Box onClick={handleCategoryProductCardClick} cursor="pointer" position="relative">
          <Flex aspectRatio={3 / 4} width={'full'} height={'full'}>
            {isDesktop ? (
              <ImageWithQuickViewButton
                imageSrc={imageSrc}
                imageAlt={imageAlt}
                onButtonClick={handleQuickViewClick}
                intl={intl}
              />
            ) : (
              <ProductImage imageSrc={imageSrc} imageAlt={imageAlt} productName={product.name} />
            )}
          </Flex>

          {showBadge && (
            <Box position="absolute" top="0" left="0" padding={'0.5rem'}>
              <Badge
                value={intl.formatMessage({ id: 'category.search.sale' })}
                variant={'solidRed'}
                size={{ base: 'small', lg: 'large' }}
                verticalAlign={'baseline'}
              />
            </Box>
          )}
        </Box>

        {product?.thumbnails?.length && (
          <SwatchSelector
            thumbnails={product?.thumbnails}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            size={isMobile ? 'small' : watcherSize}
            showText
            productSlug={`/p/${product.slug}`}
          />
        )}

        <VStack gap={0.5} alignItems="initial">
          {hasBadge && <ProductBadges badgeValues={badgeValues} />}

          <Text tabIndex={0} textStyle="body-75" color={'text-muted'}>
            {product.brand}
          </Text>

          <Text tabIndex={0} onClick={handleCategoryProductCardClick} cursor="pointer" textStyle={'body-100'}>
            {product.name}
          </Text>

          <PriceSection product={product} />
        </VStack>

        <PrRatings hitss={product} index={idx} />
      </VStack>
    </>
  )
}
