import algoliasearch from 'algoliasearch'
import { IndexUiState } from 'instantsearch.js'
import { ALGOLIA_BASE_INDEX, ALGOLIA_FILTERS, ALGOLIA_INDEX_LIST, BRAND_NAME, DEFAULT_SORTBY } from './constants'
import { PLPPriceLayouts } from './types'

const algoliaConfig = {
  appId: process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID || '',
  searchApiKey: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY || '',
}

const searchClient = algoliasearch(algoliaConfig.appId, algoliaConfig.searchApiKey)

export const algoliaIndexNameResolver = ({ locale, sortBy = DEFAULT_SORTBY }: { locale: string; sortBy?: string }) => {
  // This project has configured locales as 'en' while algolia has 'en-US'/'en-CA'
  const indexLocale = locale === 'en' ? 'en-US' : 'en-CA'

  const indexPrefix = ALGOLIA_BASE_INDEX === 'prod' ? '' : `${ALGOLIA_BASE_INDEX}_`

  const baseIndex =
    sortBy === 'primary_index'
      ? `${indexPrefix}${BRAND_NAME}_${indexLocale}`
      : `${indexPrefix}${BRAND_NAME}_${indexLocale}_${sortBy}`

  return baseIndex
}

export const getSortByFromIndex = (
  indexName: string,
  locale: string,
  indexNameResolver?: (props: { locale: string; sortBy?: string; isLoggedIn?: boolean }) => string,
) => {
  return ALGOLIA_INDEX_LIST.reduce<string | undefined>((acc, { value: sortBy }) => {
    return indexNameResolver?.({ locale, sortBy }) === indexName ? sortBy : acc
  }, undefined)
}

export const getRouteFilters = (filters: IndexUiState['refinementList'] | IndexUiState['numericMenu']) => {
  return Object.entries(filters || {}).reduce<Record<string, string>>((acc, [filter, values]) => {
    const filterAlias = getFilterAttributeUrlAlias(filter)
    if (filterAlias) {
      return {
        ...acc,
        [filterAlias]: typeof values === 'string' ? values : values.join(','),
      }
    }
    return acc
  }, {})
}

interface FilterState {
  numericMenu: {
    [attribute: string]: string
  }
  refinementList: {
    [attribute: string]: string[]
  }
}

export const getAlgoliaStateFilters = (filters: Record<string, string>) => {
  return Object.entries(filters || {}).reduce<FilterState>(
    (acc, [filterAlias, value]) => {
      const fullAttribute = getFilterRawAttribute(filterAlias)
      if (!fullAttribute) return acc
      const { attribute, type } = fullAttribute
      switch (type) {
        case 'list':
          const previousValue = acc.refinementList[attribute] || []
          return {
            ...acc,
            refinementList: {
              ...acc.refinementList,
              [attribute]: [...previousValue, ...value.split(',')],
            },
          }
        case 'numeric':
          return {
            ...acc,
            numericMenu: {
              ...acc.numericMenu,
              [attribute]: value,
            },
          }
      }
    },
    {
      numericMenu: {},
      refinementList: {},
    },
  )
}

const getFilterAttributeUrlAlias = (rawAttribute: string) => {
  return ALGOLIA_FILTERS.find((filter) => filter.attribute === rawAttribute)?.urlAlias
}

const getFilterRawAttribute = (attributeAlias: string) => {
  return ALGOLIA_FILTERS.find((filter) => filter.urlAlias === attributeAlias)
}

export const queryParamsAccepted = (queryParams, noAcceptedParams) => {
  return Object.keys(queryParams)
    .filter((key) => !noAcceptedParams.includes(key))
    .reduce((obj, key) => {
      obj[key] = queryParams[key]
      return obj
    }, {})
}

export const fetchAllStoresFacets = async () => {
  const locale = 'en'
  const indexName = algoliaIndexNameResolver({ locale })
  const index = searchClient.initIndex(indexName)

  const facetAttribute = getFilterRawAttribute('storePickup')?.attribute || 'availability.availabilestore'
  const results = await index.search('', {
    facets: [facetAttribute],
    maxValuesPerFacet: 1000,
    // We want to get all the stores available. Currently there are between 300-500 stores
  })

  if (results.facets && results.facets[facetAttribute]) {
    return Object.keys(results.facets[facetAttribute]).map((storeKey) => ({
      label: storeKey,
      count: results.facets[facetAttribute][storeKey],
    }))
  }

  return []

  /*
    By default, Algolia returns limit = DEFAULT_INITIAL_REFINEMENTS_LIMIT (6),
    This is ok with most of the facets BUT NOT OK FOR STORE
    The user store ( received from the cookie) MAY/MAY NOT be present in the initial 6 facets. We cannot keep pressing
    the show more button to get the user-selected store to appear in the list.

    We need to exactly get the store that user has selected from the cookie and display it in the facets.
    Hence we need to fetch all the stores and filter through all the stores and find the store where storeSelectedCookie.key === AlgoliaResponse.storeKey
  */
}

export const returnFilterKeysWithUnifiedValue = (bool: boolean) => {
  const newfiltersExpandState = ALGOLIA_FILTERS.reduce((acc, filter) => {
    acc[filter.attribute] = filter.attribute === 'availability.availabilestore' ? true : bool
    return acc
  }, {})
  return newfiltersExpandState
}

export const isFacetAvailable = (currentFacet: string, algoliaResults: any) => {
  const disjunctiveFacet = !!algoliaResults?.disjunctiveFacets.find(
    (disjunctiveFacet) => disjunctiveFacet.name === currentFacet,
  )
  const facet = !!algoliaResults?.facets.find((facet) => facet.name === currentFacet)

  return disjunctiveFacet || facet
}

// Function to detect if the store refinement needs to be cleared
export const clearStoreRefinement = (refinementAttributes, clearSingleRefinement) => {
  const storeRefinement = refinementAttributes.find(
    (attribute) => attribute.attribute === 'availability.availabilestore',
  )

  if (storeRefinement) {
    storeRefinement.refinements.forEach((refinement) => {
      clearSingleRefinement(refinement)
    })
  }
}

export const debounce = (func, delay) => {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func(...args)
    }, delay)
  }
}

export const defineLayout = ({ discountAmount, edsPrice, basePrice, priceOrPricesArray }): PLPPriceLayouts => {
  if (
    discountAmount ||
    (discountAmount && edsPrice) ||
    basePrice.centAmount > priceOrPricesArray.centAmount[priceOrPricesArray.centAmount.length - 1]
  )
    return PLPPriceLayouts.DISCOUNT_EVENT

  if (!edsPrice && !discountAmount) return PLPPriceLayouts.STANDARD

  if (edsPrice && !discountAmount) return PLPPriceLayouts.EDS_PROMO

  return PLPPriceLayouts.STANDARD
}

// Reducer to manage state transitions
export const actionTypes = {
  FETCH_INIT: 'FETCH_INIT',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  SET_REFINED: 'SET_REFINED',
  CLEAR_REFINED: 'CLEAR_REFINED',
  SET_IS_REFINED_BY_STORE_PICKUP: 'SET_IS_REFINED_BY_STORE_PICKUP',
}

export const refinementReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_INIT:
      return { ...state, loading: true }
    case actionTypes.FETCH_SUCCESS:
      return { ...state, stores: action.payload, loading: false }
    case actionTypes.SET_REFINED:
      return { ...state, isRefined: action.payload }
    case actionTypes.CLEAR_REFINED:
      return { ...state, isRefined: false }
    case actionTypes.SET_IS_REFINED_BY_STORE_PICKUP:
      return { ...state, isRefinedByStorePickup: action.payload }
    default:
      return state
  }
}

export const toTitleCase = (str) => {
  if (str) {
    return str.replace(/\w\S*/g, (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase())
  } else {
    return ''
  }
}

export const isGiftOrDonation = (product) => {
  const name = product?.name?.toLowerCase()
  const GIFT_CARD = 'gift card'
  const DONATIONS = 'st jude donation'

  return name.includes(GIFT_CARD) || name.includes(DONATIONS)
}
